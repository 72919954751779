import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// css
import "./App.css";
import "./assets/css/reset.css";
import "./assets/css/root.css";

// components
import Home from "./pages/home/index.jsx";

function App() {
  const router = createBrowserRouter([
    {
      // errorElement: <ErrorPage />,
      path: "/",
      // element: <NavNotAuth data={jsonData} />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
      ],
    },
  ]);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
