import React, { useEffect, useState } from "react";
import "./home.css";
import "react-phone-number-input/style.css";
import ButtonComponent from "../../components/button/index.jsx";
import AlertSmall from "../../components/alert/alertSmall.jsx";
import { app } from "../../components/firebase/firebase";
import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import "../../components/input/inputComponent.css";
import InputComponent from "../../components/input/index.jsx";

function Home() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // window.location.href = "https://dashboard.loukaaa.com/";
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const handleSignIn = async () => {
    setLoading(true);
    setError("");
    try {
      await setPersistence(auth, browserLocalPersistence);
      const authSign = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await authSign.user.getIdToken();
      await fetch("https://api.loukaaa.com/sessionLogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ idToken }),
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
      error.code === "auth/user-not-found" &&
        setError("Utilisateur non trouvé");
      error.code === "auth/wrong-password" &&
        setError("Mot de passe incorrect");
      error.code === "auth/invalid-email" && setError("Email invalide");
      error.code === "auth/too-many-requests" &&
        setError("Trop de tentatives, réessayez plus tard");
      error.code === "auth/invalid-credential" &&
        setError("Email ou mot de passe invalide");
    }
  };

  return (
    <div className="Home" id="Home">
      <div id="recaptcha-container"></div>
      <h3>Bienvenue</h3>
      <div className="text-sm">
        Veuillez entrer votre email et mot de passe pour continuer
      </div>
      <div className="form">
        {error !== "" && (
          <AlertSmall type="error" style={{ marginBottom: 20 }}>
            {error}
          </AlertSmall>
        )}
        <InputComponent
          type="text"
          placeholder="exmple@email.com"
          width="100%"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <InputComponent
          type="password"
          placeholder="Mot de passe"
          width="100%"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
      </div>
      <div className="button-container">
        <ButtonComponent
          text="Continuer"
          onClick={handleSignIn}
          icon={true}
          id="sign-in-button"
          loading={loading}
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
}

export default Home;
