import React, { Fragment } from "react";
import "./buttonComponent.css";
import LoaderComponent from "../loader/index.jsx";

// icons
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { FaCheck } from "react-icons/fa6";

function ButtonComponent({
  text,
  onClick,
  loading,
  style,
  iconRight,
  iconLeft,
  skin,
  success,
}) {
  return (
    <Fragment>
      {loading ? (
        <button
          className={"ButtonComponent " + (skin && skin)}
          onClick={onClick}
          style={style}
          disabled
        >
          <LoaderComponent />
        </button>
      ) : !success ? (
        <button
          className={"ButtonComponent " + (skin && skin)}
          onClick={onClick}
          style={style}
        >
          {iconRight === true ? (
            <HiArrowLeft className="icon right" />
          ) : (
            iconRight
          )}
          {text}
          {iconLeft === true ? (
            <HiArrowRight className="icon left" />
          ) : (
            iconLeft
          )}
        </button>
      ) : (
        <button
          className={"ButtonComponent success " + (skin && skin)}
          onClick={onClick}
          style={style}
          disabled
        >
          <FaCheck className="icon" />
        </button>
      )}
    </Fragment>
  );
}

export default ButtonComponent;
